<div [hidden]="progress >= 100">
    <div class="progress">
        <span class="small">文档加载中...<span class="percent" *ngIf="progress > 0">{{ progress }}%</span><br /></span>
        <div>
            <div class="spinner-grow text-muted"></div>
            <div class="spinner-grow text-primary"></div>
            <div class="spinner-grow text-success"></div>
            <div class="spinner-grow text-info"></div>
            <div class="spinner-grow text-warning"></div>
            <div class="spinner-grow text-danger"></div>
            <div class="spinner-grow text-dark"></div>
        </div>
        <br />
        <div class="help">首次加载较慢，如长时间打不开请点击下方云朵下载文件！</div>
        <br />
        <a href="javascript:;" (click)="fileDown(fileUrl)" class="btn btn-default btn-sm float-right"><i
                class="fas fa-cloud-download-alt"></i></a>
    </div>
</div>
<pdf-viewer [src]="fileUrl" [zoom]="1" [original-size]="true" style="width: 100%" [autoresize]="true" [rotation]="0"
    [original-size]="false" [show-all]="true" [fit-to-page]="false" [zoom]="1" [zoom-scale]="'page-width'"
    [stick-to-page]="false" [render-text]="true" [external-link-target]="'blank'" [autoresize]="true"
    [show-borders]="false" (error)="onError($event)" (on-progress)="onProgress($event)"
    (after-load-complete)="callBackFn($event)"></pdf-viewer>