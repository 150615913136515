<!--end::Subheader-->
<!--begin::Entry-->
<div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
        <!--begin::Row-->
        <div class="row">
            <div class="col-xl-12">
                <!--begin::Card-->
                <div class="card card-custom">
                    <!--begin::Header-->
                    <div class="card-header h-auto py-2">
                        <div class="card-title">
                            <h3 class="card-label">起重量限制器
                                <span class="d-block text-muted pt-2 font-size-sm">产品参数概览</span>
                            </h3>
                        </div>
                    </div>
                    <!--end::Header-->
                    <!--begin::Body-->
                    <div class="card-body py-4">
                        <div class="form-group row my-2">
                            <label class="col-4 col-form-label">规格型号:</label>
                            <div class="col-8">
                                <span class="form-control-plaintext">
                                    <span class="font-weight-bolder">QCX-H2B1</span>
                                </span>
                            </div>
                        </div>

                        <div class="form-group row my-2">
                            <label class="col-4 col-form-label">适用范围:</label>
                            <div class="col-8">
                                <span class="form-control-plaintext font-weight-bolder">0.5t~500t 各种规格型号桥门式起重机</span>
                            </div>
                        </div>
                        <div class="form-group row my-2">
                            <label class="col-4 col-form-label">综合误差:</label>
                            <div class="col-8">
                                <span class="form-control-plaintext font-weight-bolder">≤ ±5% F.S</span>
                            </div>
                        </div>
                        <div class="form-group row my-2">
                            <label class="col-4 col-form-label">动作误差:</label>
                            <div class="col-8">
                                <span class="form-control-plaintext font-weight-bolder">≤ ±3% F.S</span>
                            </div>
                        </div>
                        <div class="form-group row my-2">
                            <label class="col-4 col-form-label">传感器过载能力:</label>
                            <div class="col-8">
                                <span class="form-control-plaintext font-weight-bolder">150%</span>
                            </div>
                        </div>
                        <div class="form-group row my-2">
                            <label class="col-4 col-form-label">使用环境:</label>
                            <div class="col-8">
                                <span class="form-control-plaintext font-weight-bolder">工作温度 -20~+60℃ 相对湿度 90%RH</span>
                            </div>
                        </div>
                        <div class="form-group row my-2">
                            <label class="col-4 col-form-label">电源电压:</label>
                            <div class="col-8">
                                <span class="form-control-plaintext font-weight-bolder">
                                    AC220V(±10%)或 AC380V(±10%)
                                </span>
                            </div>
                        </div>
                        <div class="form-group row my-2">
                            <label class="col-4 col-form-label">继电器触点容量:</label>
                            <div class="col-8">
                                <span class="form-control-plaintext font-weight-bolder">
                                    7A/250VAC
                                </span>
                            </div>
                        </div>
                        <div class="form-group row my-2">
                            <label class="col-4 col-form-label">传感器防护等级:</label>
                            <div class="col-8">
                                <span class="form-control-plaintext font-weight-bolder">
                                    IP65
                                </span>
                            </div>
                        </div>
                        <div class="bg-white">
                            <ul class="product-attachments d-flex align-items-stretch clearfix">
                                <li>
                                    <span class="product-attachment-icon"><i class="far fa-file-pdf"
                                            style="font-size: 40px;"></i></span>
                                    <div class="product-attachment-info">
                                        <a href="javascript:;"
                                            (click)="fileShow('https://file.hldcloud.cn/qr-code/attachments/QCX-H2B1中英文说明书.pdf')"
                                            class="product-attachment-name"><i class="fas fa-paperclip"></i>
                                            QCX-H2B1中英文说明书.pdf</a>
                                        <span class="product-attachment-size clearfix mt-1">
                                            <span>649.03KB</span>
                                            <a href="javascript:;"
                                                (click)="fileDown('https://file.hldcloud.cn/qr-code/attachments/QCX-H2B1中英文说明书.pdf')"
                                                class="btn btn-default btn-sm float-right"><i
                                                    class="fas fa-cloud-download-alt"></i></a>
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <span class="product-attachment-icon"><i class="far fa-file-pdf"
                                            style="font-size: 40px;"></i></span>
                                    <div class="product-attachment-info">
                                        <a href="javascript:;"
                                            (click)="fileShow('https://file.hldcloud.cn/qr-code/attachments/H2B1调试说明.pdf')"
                                            class="product-attachment-name"><i class="fas fa-paperclip"></i>
                                            调试说明.pdf</a>
                                        <span class="product-attachment-size clearfix mt-1">
                                            <span>75.72 KB</span>
                                            <a href="javascript:;"
                                                (click)="fileDown('https://file.hldcloud.cn/qr-code/attachments/H2B1调试说明.pdf')"
                                                class="btn btn-default btn-sm float-right"><i
                                                    class="fas fa-cloud-download-alt"></i></a>
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!--end::Body-->

                </div>
                <!--end::Card-->
            </div>
        </div>
        <!--end::Row-->
    </div>
    <!--end::Container-->
</div>
<!--end::Entry-->