import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-qcx-h2b1',
  templateUrl: './qcx-h2b1.component.html',
})
export class QcxH2b1Component implements OnInit {
  constructor(private _router: Router) {}

  ngOnInit(): void {}
  fileShow(fileUrl: string) {
    this._router.navigate(['pdf', fileUrl]);
  }
  fileDown(fileUrl: string) {
    window.location.href = fileUrl;
  }
}
