import { Component } from '@angular/core';
import {
  ProductDto,
  ProductServiceProxy,
} from '../shared/service-proxies/service-proxies';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
})
export class ProductComponent {
  product: ProductDto;
  productId: number;
  code: string;
  constructor(
    private _router: Router,
    private productService: ProductServiceProxy,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.productId = params.Id;
    });
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.code) {
        this.code = params.code;
      }
    });
    this.getProduct(this.productId, this.code);
  }

  // tslint:disable-next-line:typedef
  getProduct(productId: number, code: string) {
    this.productService
      .get(productId, code)
      .pipe()
      .subscribe((result) => {
        this.product = result;
      });
  }
  fileShow(fileUrl: string) {
    this._router.navigate(['pdf', fileUrl]);
  }
  // tslint:disable-next-line:typedef
  fileDown(fileUrl: string) {
    window.location.href = fileUrl;
  }
  // tslint:disable-next-line:typedef
  more(id: number) {
    window.location.href =
      'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5899e5b1207b8489&redirect_uri=https://product.hldcloud.cn/' +
      id +
      '&response_type=code&scope=snsapi_base&state=more#wechat_redirect';
  }
}
