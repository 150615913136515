import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProductComponent } from './product/product.component';
import { ServiceProxyModule } from './shared/service-proxies/service-proxy.module';
import { HttpClientModule } from '@angular/common/http';
import { AppConsts } from './shared/AppConsts';
import { API_BASE_URL } from './shared/service-proxies/service-proxies';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfShowComponent } from './pdf-show/pdf-show.component';
import { QcxH2b1Component } from './product/qcx-h2b1/qcx-h2b1.component';
@NgModule({
  declarations: [
    AppComponent,
    ProductComponent,
    PdfShowComponent,
    QcxH2b1Component,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    PdfViewerModule,
    AppRoutingModule,
    ServiceProxyModule,
  ],
  providers: [
    { provide: API_BASE_URL, useFactory: () => AppConsts.remoteServiceBaseUrl },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
