import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PDFDocumentProxy, PDFProgressData } from 'ng2-pdf-viewer';
@Component({
  selector: 'app-pdf-show',
  templateUrl: './pdf-show.component.html',
  styleUrls: ['./pdf-show.component.css'],
})
export class PdfShowComponent implements OnInit {
  fileUrl: string;
  progress: number = 0;
  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.fileUrl = params.Url;
    });
  }
  ngOnInit(): void {
    (<any>window).pdfWorkerSrc = 'assets/js/pdf.worker.min.js';
  }
  fileDown(fileUrl: string) {
    window.location.href = fileUrl;
  }
  onProgress(progressData: PDFProgressData) {
    this.progress = Math.round(
      (progressData.loaded / progressData.total) * 100
    );
    console.log(progressData);
  }
  callBackFn(pdf: PDFDocumentProxy) {
    this.progress = 100;
  }
  onError(error: any) {
    console.log(error);
  }
}
