import { NgModule } from '@angular/core';
import { AppConsts } from '../AppConsts';
import * as ApiServiceProxies from './service-proxies';

@NgModule({
  providers: [ApiServiceProxies.ProductServiceProxy],
})
export class ServiceProxyModule {
  constructor() {
    AppConsts.remoteServiceBaseUrl = 'https://qrcode.hldcloud.cn';
  }
}
