<!--end::Subheader-->
<!--begin::Entry-->
<div class="d-flex flex-column-fluid">
  <!--begin::Container-->
  <div class="container">
    <!--begin::Row-->
    <div class="row">
      <div class="col-xl-12">
        <!--begin::Card-->
        <div class="card card-custom" *ngIf="product">
          <!--begin::Header-->
          <div class="card-header h-auto py-2">
            <div class="card-title">
              <h3 class="card-label">{{product.productType}}
                <span class="d-block text-muted pt-2 font-size-sm">产品参数概览</span>
              </h3>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-4">
            <div class="form-group row my-2">
              <label class="col-4 col-form-label">产品编号:</label>
              <div class="col-8">
                <span class="form-control-plaintext font-weight-bolder">{{product.productNumber}}</span>
              </div>
            </div>
            <div class="form-group row my-2">
              <label class="col-4 col-form-label">出厂日期:</label>
              <div class="col-8">
                <span class="form-control-plaintext font-weight-bolder">{{product.factoryDate|date:'yyyy-MM-dd'}}</span>
              </div>
            </div>
            <div class="form-group row my-2">
              <label class="col-4 col-form-label">规格型号:</label>
              <div class="col-8">
                <span class="form-control-plaintext">
                  <span class="font-weight-bolder">{{product.productModel}}</span>
                </span>
              </div>
            </div>
            <div *ngFor="let attribute of product.attributes">
              <div class="form-group row my-2">
                <label class="col-4 col-form-label">{{attribute.name}}:</label>
                <div class="col-8">
                  <span class="form-control-plaintext font-weight-bolder">{{attribute.value}}</span>
                </div>
              </div>
            </div>
            <div class="form-group row my-2">
              <label class="col-4 col-form-label">调试人员:</label>
              <div class="col-8">
                <span class="form-control-plaintext font-weight-bolder">
                  {{product.tester}}
                </span>
              </div>
            </div>
            <div class="form-group row my-2">
              <label class="col-4 col-form-label">测试人员:</label>
              <div class="col-8">
                <span class="form-control-plaintext font-weight-bolder">
                  {{product.debugger}}
                </span>
              </div>
            </div>
            <div class="form-group row my-2">
              <label class="col-4 col-form-label">备注:</label>
              <div class="col-8">
                <span class="form-control-plaintext font-weight-bolder">
                  {{product.remark}}
                </span>
              </div>
            </div>
            <div class="bg-white">
              <ul class="product-attachments d-flex align-items-stretch clearfix">
                <li *ngFor="let attachment of product.attachments">
                  <span class="product-attachment-icon"><i class="far fa-file-{{attachment.fileType}}"
                      style="font-size: 40px;"></i></span>
                  <div class="product-attachment-info">
                    <a href="javascript:;" (click)="fileShow(attachment.fileUrl)" class="product-attachment-name"><i
                        class="fas fa-paperclip"></i>
                      {{attachment.name}}</a>
                    <span class="product-attachment-size clearfix mt-1">
                      <span>{{attachment.formatSize}}</span>
                      <a href="javascript:;" (click)="fileDown(attachment.fileUrl)"
                        class="btn btn-default btn-sm float-right"><i class="fas fa-cloud-download-alt"></i></a>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <!--end::Body-->
          <!--begin::Footer-->
          <div class="card-footer">
            <a href="javascript:;" (click)="more(product.id)" class="btn btn-light-primary font-weight-bold">查看更多</a>
          </div>
          <!--end::Footer-->
        </div>
        <!--end::Card-->
      </div>
    </div>
    <!--end::Row-->
  </div>
  <!--end::Container-->
</div>
<!--end::Entry-->